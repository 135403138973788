body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.btn {
	cursor: pointer;
	border: none;
}

.upload-btn {
	height: 40;
	width: 150;
	margin: 10;
	background: #4d4c4c;
	border: none;
	color: #fff;
	border-radius: 5;
	cursor: pointer;
}

.login_wraper {
	max-width: 300px;
	margin: 0 auto;
	/* text-align: center; */
	padding: 20px;
	background-color: #1d3557;
	margin-top: 10%;
	color: #fff;
}

.form-control button {
	border: none;
	padding: 5px 10px;
	cursor: pointer;
}

.form-control > div {
	text-align: left;
}
.form-control {
	padding-top: 10px;
}
